.RoundBtn {
  cursor: pointer;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgb(242, 242, 242);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                  0 1px 5px 0 rgba(0, 0, 0, 0.12),
                  0 3px 1px -2px rgba(0, 0, 0, 0.2);
  margin-right: 12px; // TODO

  .icon {
    width: 20px;
    height: 20px;

    svg {
      fill: #616161;
    }
  }
  
  &:hover {
    svg {
      opacity: 0.5;
    }
  }
}
