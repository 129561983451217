.PdfMatches {
  height: 100vh;

  .screen-content {
    margin-bottom: 0;
  }

  &__container {
    display: flex;
    flex-flow: column;
    overflow: hidden;
    margin-bottom: 1rem;
    max-width: 768px;
    background-color: #f7f7f7;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;

    @media screen and (max-device-width: 769px) and (min-device-width: 320px) {
      padding: .5rem 1rem;
    }
  }

  &__search-results {
    overflow: scroll;
    flex: 1;
    list-style: none;

    @media screen and (max-device-width: 769px) and (min-device-width: 320px) {
      padding: 0 1rem;
    }
  }

  &__search-result {
    padding: 0.5rem 0 0.5rem 0;
    transition: all .2s;
    font-size: 1rem;
    font-weight: 300;
    cursor: pointer;

    &:hover {
      >div {
        color: #666;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid #cfdbe6;
    }
  }

  &__search-result-page {
    font-size: 12px;
    color: #666;
  }

  &__search-result-match {
    line-height: 1.2rem;
    color: #000;
    font-size: 16px;

    em {
      font-weight: bold;
    }
  }
}
