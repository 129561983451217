@import "common";
@import './variables';

.SearchInput {
  padding: 8px 16px;

  .SearchInput__inner-container {
    height: 44px;
    display: flex;
    gap: 12px;
    flex-shrink: 0;
    align-items: center;
    border: none;
  }

  .SearchInput__search-icon {
    height: 20px;
    width: 20px;

    .icon.icon-search {
      height: auto;
      width: auto;
      padding: 0;
      margin-right: 0;
    }
  }

  .fill {
    fill: $infiniti-grey-accent;
  }

  .SearchInput__icon-post {
    display: none;
  }

  .icon-cancel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-mobile {
    .SearchInput__inner-container {
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #E2E2E2;
      padding: 0 14px;
      box-shadow: none;
    }

    & + .topics-subtitle {
      background-color: transparent !important;
      box-shadow: none;
      padding: rem(24) rem(18) rem(10) rem(20);
      font-weight: 700;

      hr {
        width: 100%;
        height: 1px;
        border: 0;
        background: rgba(0, 0, 0, 0.1);
        margin: 0;
        margin-top: rem(17);
      }
    }

    .SearchInput__icon-post {
      display: none;
    }

    .icon-cancel .fill {
      fill: #666;
    }
  }

  input {
    flex: 1;
    font-size: rem(20);
    color: #000;
    background: transparent;
    border: none;
    outline:none;
    height: rem(34);
    line-height: rem(34);
    margin: 0;
    padding: 0;

    &::placeholder {
      color: #666666;
    }

    @include media-mobile {
      font-size: rem(16);
    }
  }

  .icon.icon-search {
    width: rem(21);
    height: rem(20);

    .fill {
      fill: #828282;
    }
  }

  button.cancel-search {
    cursor: pointer;
    align-self: baseline;
    height: 100%;
    padding: 0 12px;
    position: relative;
    right: -12px;

    @include media-desktop {
      display: none;
    }

    .icon.icon-cancel {
      width: rem(18);
      height: rem(22);

      .stroke {
        stroke: #000;
      }
    }
  }

}


