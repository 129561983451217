@import 'common';

.Guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  position: relative;

  @include media-desktop(){
    .screen-head {
      margin-bottom: rem(55);
    }
  }

  .transition-wrapper {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
