$search-panel-width: 250px;

.SearchResults {
  width: $search-panel-width;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 2;
  transform: translateX($search-panel-width);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.22);
  background-color: #fff;
  display: flex;
  flex-flow: column;
  transition: transform .2s ease-in;

  &--open {
    transform: translateX(0);
  }

  > ul {
    padding: 0;
    margin: 0;
  }

  &__header {
    padding: 12px;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.22);
    justify-content: space-between;

    > svg {
      height: 24px;
      width: 24px;
    }

    .SortSelector .Menu {
      right: 12px;

      @media screen and (max-device-width: 769px) and (min-device-width: 320px) {
        right: 12px;
      }
    }
  }

  &__items {
    flex: 1;
    overflow: scroll;
  }

  &__item {
    cursor: pointer;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.22);
    margin: 12px;
    font-size: 10px;
    line-height: 1.2;

    &--selected {
      border: 1px solid;
    }

    &-page {
      margin-bottom: 4px;
    }

    &-content {
      color: #000;

      em {
        font-weight: bold;
      }
    }
  }
}
