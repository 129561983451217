@import 'common';

.VehicleList {
  @extend .container;

  display: flex;
  flex-flow: column nowrap;
  justify-content:flex-start;
  align-content: flex-start;
  padding: rem(30) rem(60) rem(30) rem(115);

  h2 {
    font-size: rem(14);
    margin-bottom: rem(10);
    text-transform: uppercase;

    border-bottom: 1px solid #bfc7cc;
    font-weight:bold;
    color: #767676;
  }
  .group {
    flex: 0 0 rem(210);
    padding: 0;
    margin: 0 rem(40) rem(30) 0;
  }
  .list {
    display: flex;
    flex-flow: row wrap;
  }
  .model {
    flex-basis: rem(210);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: rem(10) 0;
    transition: all 0.3s;
    text-decoration: none;

    &.slick-active {
      transform: scale(1.2) translateX(-2%);
    }
    .photo {
      background-color: transparent;
      background-position:center center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      padding-top: 70%; //aspect ratio 4:3 or ?

      margin-bottom: rem(10);
    }
    .name {
      font-size:rem(18);
      text-transform: uppercase;
      font-weight:normal;
      text-align: left;
    }
  }
  @include media-edge(".model") {
    display: inline-block;
  }

  .breadcrumb {
    font-size: rem(14);

    line-height: rem(34);
    margin: rem(-30) 0 rem(20);
    padding: rem(15) rem(15) rem(15) rem(0);

    font-weight:500;
    position: relative;

    flex-shrink:0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    text-transform: uppercase;

    box-shadow: inset 0 -1px #E2E2E2;
    color: #b2b2b2;

    li {
      display: flex;
      align-items: center;

      a {
        text-decoration: none;
        color: inherit;
      }
      .icon {
        display: block;
        margin: 0 rem(22);

        width: rem(4);
        height:rem(7);
        transform: rotate(180deg);

        .stroke {
          stroke: #b2b2b2;
        }
      }

      &:last-child {
        .icon {
          display: none;
        }
        color: #000;
      }
    }

    &.inactive li:last-child {
      color: inherit;
    }
  }
}
