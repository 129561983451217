@import 'common';
@import "VehicleGroupSelector.scss";
@import "VehicleModelSelector.scss";

.VehicleModelFamily {
  @extend .VehicleGroupSelector;
  @extend .VehicleModelSelector;

  .group {
    height:auto;
  }

  .model-group {
    display: flex;
    align-items:center;
    text-decoration: none;
    color: inherit;
  }

  .model-container {
    width:100%;

    .model-inner {
      @extend %visible;
    }

    .back {
      border: none;
      display: flex;
      align-items:center;
      width: rem(25);
      height: rem(25);
      cursor: pointer;
      background: transparent;

      .icon.icon-arrow-left {
        width: rem(8);
        height:rem(14);

        .stroke {
          stroke: #000;
        }
      }
    }
  }

}
