@import "common";

.ErrorMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  min-height: 100%;

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: rem(30) 0;
    width:rem(210);
    text-align: center;
    font-weight:300;
  }

  &.inverse {
    // see brand specific theme coloring

    .inner {
      color: inherit;
    }
  }

  &.inline {
    min-height: auto;
  }

  .icon {
    width: rem(74);
    height: rem(90);
    margin-bottom: rem(36);

    .fill {
      fill: #E0E6EC;
    }
  }
  h1 {
    font-size:rem(20);
    font-weight:500;
    color: #000;
  }
  .message {
    display: block;
    font-weight:300;
    line-height:120%;
    padding: rem(25) 0;
  }
  .PrimaryButton {
    display: block;
    min-width: rem(180);
    white-space:nowrap;
  }
}
