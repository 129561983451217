$infiniti-screenhead-background: #ebebeb;
$infiniti-primary-color: #000;
$infiniti-primary-blue: #020B24;
$infiniti-secondary-color: #fff;
$infiniti-regular-color: #7c8d98;
$infiniti-contrast-color: #000;
$infiniti-background-color: #f2f2f2;
$infiniti-hotspot-color: #D0573A;

$infiniti-white: #FFFFFF;
$infiniti-grey-text: #BBB8C5;
$infiniti-grey-accent: #828282;
$infiniti-border-color: #E2E2E2;
