@import 'common';

.Loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;

  .status {
    text-align: center;
    font-weight:300;
    color: #fff;
  }

  .loader-default {
    width: rem(70);
    text-align: center;

    & > div {
      width: rem(12);
      height: rem(12);
      margin: rem(2);

      border-radius: 100%;
      display: inline-block;
      animation: spinner-bounce 1.4s infinite ease-in-out both;

      &:nth-child(1) {
        animation-delay: -0.32s;
      }
      &:nth-child(2) {
        animation-delay: -0.16s;
      }
    }

    @keyframes spinner-bounce {
      0%, 80%, 100% {
        transform: scale(0); }
      40% {
        transform: scale(1.0);
      }
    }
  }

  .loader-ios {
    $loader-size: rem(36) !default;

    font-size: $loader-size;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;

    & > div {
      position: absolute;
      left: .4629em;
      bottom: 0;
      width: .074em;
      height: .2777em;
      border-radius: .0555em;
      background-color: transparent;
      transform-origin: center -.2222em;
      animation: spinner-fade 1s infinite linear;

      $animation-delay: 0s;
      $blade-rotation: 0deg;

      @for $i from 1 through 12 {
        &:nth-child(#{$i}) {
          animation-delay: $animation-delay;
          transform: rotate($blade-rotation);

          $blade-rotation: $blade-rotation + 30;
          $animation-delay: $animation-delay + .083;
        }
      }
    }
  }

  .loader-android {
    $loader-size: rem(36) !default;

    display: inline-block;
    font-size: $loader-size;
    animation: 2s rotate infinite linear;
    width: 1em;
    height: 1em;

    circle {
      fill: transparent;
      stroke-width: 0.1em;
      stroke-linecap: round;
      stroke-dasharray: 2.3525em 0.4705em;
      animation: 1.5s strokeDashArray infinite linear;
    }

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    @keyframes strokeDashArray {
      from {
        stroke-dasharray: 2.3525em 0.4705em;
        stroke-dashoffset: 0;
      }

      10% {
        stroke-dasharray: 2.3525em 0.4705em;
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dasharray: 0.001em 2.825em;
        stroke-dashoffset: -2.826em;
      }

      60% {
        stroke-dasharray: 0.001em 2.825em;
      }

      to {
        stroke-dasharray: 2.3525em 0.4705em;
        stroke-dashoffset: -2.826em;
      }
    }
  }

  &.inverse {
    // see brand specific theme coloring

    .status {
      color: inherit;
    }
  }

}

