@import 'common';

.VehicleGroupSelector {
  display: block;
  position: relative;
  flex:1 0 auto;

  .group-container {
    position: absolute;
    width:100%;
    height: 100%;

    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    // DO NOT REMOVE THIS, WILL CAUSE RENDER PROBLEMS ON CHROME
    transform: translateZ(0);

    &.stop {
      overflow-y: hidden;

      .group {
        transition: none;
      }
    }

    .group-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: rem(20);
      padding-left: rem(16);
      min-height: 100%;
      margin-bottom: 1px;
    }
  }

  .group-divider {
    height:rem(30);
    margin-top: rem(-15);

    &:first-child {
      height:rem(20);
    }
  }
  .group {
    width: 100%;
    height: 0;
    box-sizing: content-box;
    margin: rem(7) rem(5);
    display: flex;
    flex: 0 0 auto;
    align-items:flex-start;

    background: #fff;
    color: #2b2b2b;
    border-radius: rem(4);

    transition: all 0.3s ease;
    min-height: rem(56);

    overflow: hidden;

    & > a {
      display: flex;
      align-items: center;
      text-decoration: none;
      position: relative;
      width: 100%;
      height: 100%;
      font-size: rem(18);
      font-weight: 700;
      color: #2b2b2b;
      padding: rem(16) rem(21);
      cursor: pointer;
      text-align: left;
      overflow: hidden;
    }
  }

  &:before, &:after {
    content: " ";
    display: block;
    position: absolute;
    width: 100%;
    height: rem(30);
    z-index:1;
    left:0;
    pointer-events: none;
  }
  &:before {
    top: 0;
    box-shadow: inset 0 rem(10) rem(15) rem(-5) rgba(0,0,0,0.2);
  }
  &:after {
    bottom:0;
    box-shadow: inset 0 rem(-10) rem(15) rem(-5) rgba(0,0,0,0.2);
  }
}
