$btn-dimensions: 20px;

.CloseBtn {
  display: flex;
  height: $btn-dimensions;
  width: $btn-dimensions;
  cursor: pointer;

  &:hover {
    opacity: .75;
  }
}
