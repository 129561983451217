
@import '../styles/variables';

.screen-head {
  .ScreenHead {
    background-color: transparent;
    flex: 1;

    &__search {
      height: 44px;
      border-radius: 4px;
      border: 1px solid $infiniti-border-color;
      background-color: $infiniti-white;
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 0 14px;
      cursor: pointer;
    }

    &__search-icon {
      height: 20px;
      width: 20px;

      .icon.icon-search {
        height: auto;
        width: auto;
        padding: 0;
        margin-right: 0;
      }

      .fill {
        fill: $infiniti-grey-accent;
      }
    }

    &__search-placeholder {
      font-size: 16px;
      line-height: 21px;
      color: $infiniti-grey-text;
    }
  }
}
