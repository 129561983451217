@import "utils";

.VehicleYearSelector {
  position: relative;

  align-items: center;
  height:rem(78);
  flex-shrink:0;
  padding: 0;

  @include media-desktop {
    padding-left: rem(115);
    height:rem(55);
    background-color: #000;
  }

  &__label {
    display: block;
    margin-bottom: rem(8);
    display: flex;
    padding-left: rem(16);
    align-self: flex-start;
    font-size: rem(13);
    font-weight: 400;
    line-height: rem(18);
  }

  .wrapper {
    height:rem(48);
    align-items: center;

    &:focus {
      outline: 0;
    }

    @include media-desktop {
      display: flex;
      &:first-child > a {
        margin-left:rem(-6);
      }
    }

    @include media-mobile {
      &:first-of-type a {
        margin-left: 0 !important;
      }
    }

    & > a {
      display: block;
      text-decoration: none;
      line-height:normal;
      color: white;
      position: relative;
      font-size: rem(20);
      opacity:.4;
      margin: rem(10) rem(8) 0 rem(8);
      padding:0 rem(8);
      cursor: pointer;

      transition: all .2s;

      @include media-desktop {
        font-size:rem(18);
        margin:0 rem(10) rem(4) rem(10);
        padding:0 rem(10);
      }

      &.current {
        opacity:1;
        transform-origin: center center;
        transform: scale(1.2) translateY(-1px);

        &:after {
          content: '';
          display: block;
          position: absolute;
          width:rem(5);
          height:rem(5);
          background: white;
          border-radius: 100%;
          top: 100%;
          left: 50%;
          margin-left:rem(-3);
          margin-top: 1px;
        }
      }
    }
  }
}
