@import 'common';

.VehicleModelSelector {

  width: 100%;

  .model-container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height:100%;
    overflow: hidden;

    .model-group {
      display: flex;
      align-items: center;
      height: rem(56);
      font-size: rem(18);
      font-weight: 700;
      padding: rem(16) rem(21);
      border-bottom: 1px solid opacify(#d9dee3, 0.5);
    }

    .model-inner {
      flex:0 0 auto;
      padding: rem(24) rem(9) rem(24) rem(21);
    }

    .close {
      position: absolute;
      top: rem(15);
      right: rem(15);
      border: none;
      width: rem(25);
      height: rem(25);
      cursor: pointer;
      background: transparent;

      &:before, &:after {
        content: ' ';
        display: block;
        position: absolute;
        width:rem(17);
        height:rem(2);
        transform: rotate(45deg);
        background: #999999;
        left:rem(4);
        top: 50%;
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  .model-container .model-inner,
  .proceed {
    @extend %hidden;
  }
  &.visible .model-container .model-inner,
  &.visible .proceed{
    @extend %visible;
  }

  .model {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(32);
    transition: all 0.3s;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .name {
      font-size: rem(16);
      padding: 0;
      text-decoration: none;
      color: inherit;

      &.name-long {
        font-size: rem(18);
      }

      &.name-line {
        font-size: rem(20);
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
    }
    .icon.icon-arrow-left {
      position: absolute;
      width: rem(8);
      height:rem(14);
      right: rem(15);
      top: 50%;
      margin-top:rem(-7);
      transform: rotate(180deg);

      .stroke {
        stroke: #bfc7cc;
      }
    }
  }

  .proceed {
    text-align: center;

    flex: 0;
    align-items: center;
    justify-content: center;
    display: flex;

    &__link {
      padding: rem(13) rem(40);
    }
  }
}
