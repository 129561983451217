@import 'common';
@import "variables";

.TopicsList {
  $list-color: #495360;
  $icon-color: #C0C0C0;

  font-size: rem(18);
  font-weight: 100;
  line-height: rem(28);

  @include media-mobile {
    padding: 16px;
    background: $infiniti-screenhead-background;
  }

  &__match-aside {
    display: inline-block;
  }

  &__match-subtitle {
    font-size: rem(12);
    color: #666;
  }

  &__match-subtitle--aside {
    display: inline-block;
    position: relative;
    margin-left: 13px;
    margin-bottom: -3px;

    &:before {
      content: '|';
      display: block;
      position: absolute;
      left: -6px;
      top: -1px;
    }
  }

  li {
    margin-bottom: rem(10);
  }


  .Loader--match {
    display: inline-flex;
    width: auto;

    .loader-default > div {
      width: 0.4rem;
      height: 0.4rem;
    }
  }

  // styles for top level browse publications
  &.publications {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);

    li {
      height: rem(135);
      margin-bottom: 0;

      .item-inner {
        flex-flow: column-reverse;
        justify-content: space-evenly;
        align-items: center;

        .item-title {
          flex: 0 1 auto;
          padding: 0;
          text-align: center;
          line-height: rem(22);
          opacity: 0.8;
          font-weight: 700;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      .item-iconbar .icon {
        width: rem(40);
        height: auto;
        margin: 0;

        &.icon-folder {
          width: rem(35);
        }
      }
    }
  }

  li {
    box-shadow: 0px rem(4) rem(8) rgba(0, 0, 0, 0.04);
    border-radius: rem(4);
    background: #f7f7f7;

    @include media-desktop() {
      box-shadow: none;
    }

    display: flex;

    .item-inner {
      display: flex;
      flex-flow: row nowrap;
      text-decoration: none;
      position: relative;
      flex: 1;
      padding: rem(12) rem(25) rem(12) rem(16);
      align-items: flex-start;

      .item-title {
        flex: 1;
        padding-right:rem(20);
        transition: all .2s;
        font-weight: 400;
        font-size: rem(16);
        line-height: rem(24);
        color: #000;
        letter-spacing: -0.31px;

        &.return {
          //color: lighten($list-color, 20%);
          font-weight:300;
        }
      }

      @include media-desktop {
        //transition: all .2s;
        //transform-origin: 0 0;
        padding: rem(10) 0 rem(10) 0;
        flex-direction: row-reverse;
        align-items: flex-start;

        .item-title {
          padding-left:rem(22);
          padding-right:0;
          font-weight:300;
          font-size:rem(20);
        }

        .item-iconbar {
          .icon {
            width:rem(20);
            height:rem(20);
            margin: rem(4) 0 0 rem(2);

            &.icon-folder {
              width:rem(23);
              height:rem(18);
            }
            &.icon-list-topic {
              width:rem(18);
              height:rem(22);
            }
          }
        }

        &:hover {
          //transform: scale(1.02);

          .item-title {
            color: #000;
          }
          .icon .fill {
            fill: darken($icon-color, 10%);
          }
        }
      }
    }

    .item-media {
      display: flex;
      align-items:center;
      justify-content:center;
      width:rem(55);
      padding-right:rem(20);

      @include media-desktop {
        padding-top: rem(2);
      }
      cursor: pointer;

      .icon {
        display: block;
        width: rem(24);
        height: rem(24);

        .fill {
          fill: #76787a;
        }
        .stroke {
          stroke: #76787a;
        }

        &.icon-binocular {
          width: rem(32);
          height: rem(32);
        }

        &.icon-book,
        &.icon-search {
          width: rem(26);
          height: rem(24);
        }

        &.icon-flag {
          width: rem(30);
          height: rem(30);
        }

        &.icon-warning {
          width: rem(30);
        }
      }
    }

    .item-iconbar {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      .icon {
        display: block;
        width: rem(20);
        height: rem(20);
        margin: rem(6) 0 0 rem(5);

        &.icon-list-topic,
        &.icon-list-film {
          margin-top: 0;
        }

        .fill {
          transition: fill .2s;
          fill: $icon-color;
        }


        &.icon-arrow-left {
          width: rem(8);
          height:rem(14);
          transform: rotate(180deg);

          .stroke {
            stroke: $list-color;
          }

        }
      }
    }
  }

  &.home {
    padding-left:rem(18);
    li {
      .item-inner {
        line-height:33px;
        align-items: center;
      }
      .item-iconbar .icon {
        margin: 0;
      }
    }
  }
}
