.PdfViewer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6e6e6e;
  overflow: hidden;

  &__actions {
    height: 50px;
    width: 100%;
    padding: 24px 24px 0;
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    @media screen and (max-device-width: 769px) and (min-device-width: 320px) {
      justify-content: flex-end;
    }
  }

  &__scale {
    display: flex;
    margin: 4px 0 12px;

    @media screen and (max-device-width: 769px) and (min-device-width: 320px) {
      display: none;
    }
  }

  &__list-btn {
    .icon {
      width: 32px;
      height: 32px;
    }
  }
}
